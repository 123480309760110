import Nav from "./components/Nav";
import WhatIsPruu from "./components/WhatIsPruu";
import Image from "./components/Image";
import Features from "./components/Features";
import WhyUse from "./components/WhyUse";
import Links from "./components/Links";
import EmailCollectionForm from "./components/SendEmail";

export default function App() {
  return (
    <div>
      <Nav />
      <div className="flex justify-between p-8">
        <div className="pl-20 pt-20">
          <WhatIsPruu />
        </div>
        <div>
          <Image />
        </div>
      </div>
      <div className="p-8">
        <Features />
      </div>

      <div>
        <WhyUse />
      </div>
      <EmailCollectionForm />
      <div>
        <Links />
      </div>
    </div>
  );
}
