import React, { useState } from "react";

function EmailCollectionForm() {
  const [email, setEmail] = useState("");
  const [submitted, setSubmitted] = useState(false);

  const handleInputChange = (e) => {
    setEmail(e.target.value);
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    // Validate the email address (you can use a more robust validation method)
    if (!isValidEmail(email)) {
      alert("Please enter a valid email address.");
      return;
    }

    // Perform your desired action with the collected email (e.g., store it in a database)
    // For this example, we'll simply log it to the console.
    console.log("Collected email:", email);

    // Mark the form as submitted
    setSubmitted(true);
  };

  const isValidEmail = (email) => {
    // Basic email validation regex (you can use a more comprehensive regex)
    const emailRegex = /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/;
    return emailRegex.test(email);
  };

  return (
    <div className="min-h-screen flex flex-col items-center justify-center bg-black text-white font-sans">
      <div className="text-4xl font-semibold mb-6 mt-6">
        Subscribe to Our Newsletter
      </div>

      <div className="max-w-md p-6 rounded-lg bg-white shadow-md mb-6">
        {!submitted ? (
          <form onSubmit={handleSubmit} className="space-y-4">
            <label htmlFor="email" className="block text-black">
              Email:
            </label>
            <input
              type="email"
              id="email"
              name="email"
              value={email}
              onChange={handleInputChange}
              placeholder="Enter email"
              required
              className="w-full px-3 py-2 border border-gray-300 rounded focus:outline-none focus:border-black"
            />
            <button
              type="submit"
              className="w-full bg-black text-white py-2 rounded hover:bg-white hover:text-black transition duration-300 ease-in-out"
              placeholder="Enter your email"
            >
              Subscribe
            </button>
          </form>
        ) : null}
      </div>

      {/* Place the message outside of the form */}
      {submitted && (
        <div className="text-center">
          <p className="text-green-500">
            Thank you for subscribing! You will receive our newsletter.
          </p>
        </div>
      )}
    </div>
  );
}

export default EmailCollectionForm;
