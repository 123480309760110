function VirtualDate() {
  function onClick() {
    window.location.href =
      "https://calendly.com/passportbroafrica/3-question-meeting";
  }

  return (
    <button
      className="text-2xl bg-slate-950 text-white p-4 rounded-lg font-bold"
      onClick={onClick}
    >
      GO ON A VIRUAL DATE NOW
    </button>
  );
}

export default VirtualDate;
