import React from "react";
import CalendlyButton from "./CalendlyButton";

const Footer = () => {
  return (
    <div className="bg-black text-white py-8">
      <div className="container mx-auto">
        <div className="flex flex-col md:flex-row justify-between items-center">
          <div className="text-center md:text-left">
            <div className="text-2xl font-bold">Contact Us</div>
            <div className="text-lg">Email: letstalk@passportbro.africa</div>
          </div>
          <div className="text-center mt-4 md:mt-0">
            <div className="text-xl font-bold">Am Interested</div>
            <div className="text-lg">Ask our team 3 questions on zoom,</div>
            <div className="text-lg">about our services</div>
          </div>
          <div className="mt-4 md:mt-0">
            <CalendlyButton />
          </div>
        </div>
      </div>
    </div>
  );
};

export default Footer;
