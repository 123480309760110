import React from "react";
import { Link } from "react-router-dom";
import IamInterested from "./IamInterested";

const Nav = () => {
  return (
    <nav className="shadow-lg sticky top-0 z-50 bg-white">
      <div>
        <div className="flex space-x-7 justify-between">
          <div className="flex items-center py-2 px-2">
            <img alt="logo" src="./logo.png" width={50} />
            <div className="px-2">
              <h1 className="font-semibold text-xl">
                <Link to="/">PASSPORT BRO AFRICA</Link>
              </h1>
            </div>
          </div>

          <div className="p-2 px-2 pr-5">
            <div>
              <IamInterested />
            </div>
          </div>
        </div>
      </div>
    </nav>
  );
};

export default Nav;
