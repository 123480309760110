import React from "react";

const Image = () => {
  return (
    <div className="pl-3">
      <img width={700} alt="cards" src="/landing.png" />
    </div>
  );
};

export default Image;
