import React from "react";

function IamInterested() {
  function onClick() {
    window.location.href =
      "https://calendly.com/passportbroafrica/3-question-meeting";
  }

  return (
    <button
      className="bg-black text-white font-bold p-3 rounded-lg md:p-4 md:text-xl lg:text-2xl"
      onClick={onClick}
    >
      ASK 3 QUESTIONS,FREE, ZOOM
    </button>
  );
}

export default IamInterested;
