function CalendlyButton() {
  function onClick() {
    window.location.href =
      "https://calendly.com/passportbroafrica/3-question-meeting";
  }

  return (
    <button
      className="bg-white text-black p-4 rounded-lg font-bold"
      onClick={onClick}
    >
      Book a free 10 min call
    </button>
  );
}

export default CalendlyButton;
