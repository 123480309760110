import React from "react";

const styles = {
  container: {
    padding: "0 8px",
  },
  heading: {
    fontSize: "2rem",
    fontWeight: "bold",
  },
  paragraph: {
    fontSize: "1.6rem",
    lineHeight: "1.4",
    marginBottom: "1.5rem",
  },
};

const WhyUse = () => {
  return (
    <div style={styles.container}>
      <div style={styles.heading}>KENYAN WOMEN</div>
      <div style={styles.paragraph}>
        Kenya stands out as one of the largest and most rapidly growing
        economies in Africa. With a population of approximately 50 million
        people, Kenya boasts a robust democratic governance system and a
        commitment to social welfare. This African nation has made substantial
        investments in critical infrastructure, including the development of a
        digital superhighway.
      </div>
      <div style={styles.paragraph}>
        Moreover, Kenya takes pride in its youthful and dynamic workforce, and
        notably, around 90% of its energy is derived from renewable sources.
        When it comes to Kenyan women, they, like many African women, are deeply
        rooted in strong African cultural traditions. A striking feature of
        Kenyan society is that the majority of women are raised within complete
        nuclear families where both parents play active roles in their
        upbringing. This nurturing environment helps shape Kenyan women into
        competent, stable, and highly respectable individuals within their
        society.
      </div>
      <div style={styles.paragraph}>
        It's essential to highlight that their upbringing extends beyond just
        their immediate family; the community and relatives at large play
        integral roles in their development, exemplifying the African proverb
        that states, "It takes a village to raise a child." Kenyan women are
        also known for their high levels of education and expertise. Many have
        pursued education ranging from undergraduate degrees to even earning
        Ph.D. qualifications. This emphasis on education equips them with the
        knowledge and skills to excel in various fields.
      </div>
      <div style={styles.paragraph}>
        In relationships, Kenyan women often expect the man to assume a
        leadership role, while they, in return, take on responsibilities such as
        caring for the man, the children, and managing household duties. Their
        upbringing instills in them a deep sense of nurturing and support,
        allowing men to focus on their work and endeavors, knowing that their
        Kenyan partners will fulfill their traditional feminine roles, a quality
        cherished by many. Furthermore, Kenyan women exhibit a strong
        entrepreneurial spirit.
      </div>
      <div style={styles.paragraph}>
        When you are with a Kenyan woman, you not only have a life partner but
        also a business partner who is ready to engage in investments and
        explore financial opportunities with you. In summary, Kenya's thriving
        economy and its women's unique upbringing, education, and cultural
        values make Kenyan women appealing to many for their stability,
        respectfulness, and commitment to both personal and financial aspects of
        a relationship.
      </div>
    </div>
  );
};

export default WhyUse;
