import React from "react";

const styles = {
  container: {
    padding: "20px",
  },
  heading: {
    fontSize: "2rem",
    fontWeight: "bold",
    paddingBottom: "10px",
  },
  paragraph: {
    fontSize: "1.6rem",
    lineHeight: "1.4",
    marginBottom: "1.5rem",
  },
};

const Features = () => {
  return (
    <div style={styles.container}>
      <h1 style={styles.heading}>PASSPORT BRO MINDSET</h1>
      <div style={styles.paragraph}>
        The Passport Bro mindset is born from concerns about contemporary
        Western dating and marriage culture. A central grievance is the
        perception that many Western women exhibit argumentative, manipulative,
        and masculine traits, such as abrasive behavior and an overt emphasis on
        anti-male feminist views, at the expense of traditional gender roles.
      </div>
      <div style={styles.paragraph}>
        Passport Bros believe these behaviors disrupt harmonious relationships,
        contributing to disconnects in the Western dating scene. Additionally,
        Passport Bros express concerns about the celebration of sexually
        explicit and provocative behavior in Western hookup culture. They argue
        that this, coupled with the admiration of female role models promoting
        manipulation and financial exploitation, erodes traditional values,
        contributing to what they label as "toxic femininity" in Western women.
      </div>
    </div>
  );
};

export default Features;
