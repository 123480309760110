import React from "react";
import VirtualDate from "./BookAViirualDate";

const WhatIsPruu = () => {
  return (
    <div>
      <h1 className="text-3xl md:text-4xl lg:text-5xl font-extrabold text-center md:text-left">
        JOIN OUR COMMUNITY <br /> OF WESTERN
        <br />
        MEN LOOKING FOR A <br /> BETTER LIFE IN
        <br />
        KENYA
      </h1>
      <div className="p-3">
        <VirtualDate />
      </div>
    </div>
  );
};

export default WhatIsPruu;
